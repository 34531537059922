import React from "react";
import { Link, useParams } from "react-router-dom";

import Navbar from "../../components/navbar";
import Blogs from '../../components/blogs'
import BlogSidebar from "../../components/blog-sidebar";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";

import blogImg from "../../assets/images/blog/9.jpg"
import { blogData } from "../../data/data";

export default function BlogDetail(){
    let params = useParams();
    let id = params.id
    let data = blogData.find((item)=>item.id === parseInt(id))
    return(
        <>
        <Navbar navclass="defaultscroll is-sticky" navlight={true} manuclass="justify-end nav-light"/>
        <section className="relative table w-full items-center py-36 bg-[url('../../assets/images/bg/cta.jpg')] bg-top bg-no-repeat bg-cover">
            <div className="absolute inset-0 bg-gradient-to-b from-slate-900/60 via-slate-900/80 to-slate-900"></div>
            <div className="container relative">
                <div className="grid grid-cols-1 pb-8 text-center mt-10">
                    <h3 className="text-4xl leading-normal tracking-wider font-semibold text-white">{data?.title ? data.title : 'CT DStv & CCTV Experts: Your Trusted Installation Partner'}</h3>

                    <ul className="list-none mt-6">
                        <li className="inline-block text-white/50 mx-5"> <span className="text-white block">Author :</span> <span className="block">CT DStv & CCTV Experts Team</span></li>
                        <li className="inline-block text-white/50 mx-5"> <span className="text-white block">Date :</span> <span className="block">{data?.date ? data.date : '19th August 2024'}</span></li>
                        <li className="inline-block text-white/50 mx-5"> <span className="text-white block">Time :</span> <span className="block">5 Min Read</span></li>
                    </ul>
                </div>
            </div>
            
            <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                <ul className="tracking-[0.5px] mb-0 inline-block">
                    <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">CT DStv & CCTV Experts</Link></li>
                    <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><i className="mdi mdi-chevron-right"></i></li>
                    <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">Blog Detail</li>
                </ul>
            </div>
        </section>

        <section className="relative md:py-24 py-16">
            <div className="container">
                <div className="grid md:grid-cols-12 grid-cols-1 gap-6">
                    <div className="lg:col-span-8 md:col-span-6">
                        <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-800">

                            <img src={data?.image ? data.image : blogImg} alt=""/>

                            <div className="p-6">
                                <p className="text-slate-400">At CT DStv & CCTV Experts, we pride ourselves on providing top-notch installation services tailored to meet your needs. From DStv installations to CCTV setups, our team is dedicated to ensuring your home or business is secure and well-connected.</p>
                                <p className="text-slate-400 italic border-x-4 border-red-500 rounded-ss-xl rounded-ee-xl mt-3 p-3">" Our commitment to quality service is what sets us apart. Trust us to handle your installations with the utmost care and professionalism. "</p>
                                <p className="text-slate-400 mt-3">Whether you need TV mounting, Openview installations, or even air conditioning services, we are here to help. Contact us today for reliable and efficient service.</p>
                            </div>
                        </div>
                    </div>

                    <BlogSidebar/>
                </div>
            </div>

            <Blogs/>
        </section>
        <Footer/>
        <Switcher/>
        </>
    )
}
