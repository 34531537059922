import React, { useState } from "react";
import { Link } from "react-router-dom";

import travel from '../assets/images/travel-train-station.svg'

import Navbar from "../components/navbar";
import Footer from "../components/footer";
import Switcher from "../components/switcher";

import {FiPhone, FiMail,FiMapPin, FiX} from '../assets/icons/vander'

export default function Contact() {
    const [modal, setModal] = useState(false);

    return (
        <>
            <Navbar navclass="defaultscroll is-sticky" navlight={false} manuclass="justify-end"/>
            <div className="container-fluid relative mt-20">
                <div className="grid grid-cols-1">
                    <div className="w-full leading-[0] border-0">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d105943.30075755258!2d18.46523815988526!3d-33.92239561055194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc5a50a2e8cc2b%3A0xfe87b80e35138d46!2sParow%20Civic%20Centre!5e0!3m2!1sen!2sza!4v1723773650305!5m2!1sen!2sza" 
                        style={{ border: '0' }} 
                            title="location-map" 
                            className="w-full h-[500px]"
                       >
                            
                        </iframe>
                    </div>
                </div>
            </div>
            <section className="relative lg:py-24 py-16">
                <div className="container lg:mt-24 mt-16">
                    <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6">
                        <div className="text-center px-6">
                            <div className="relative text-transparent">
                                <div className="size-20 bg-red-500/5 text-red-500 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                    <FiPhone />
                                </div>
                            </div>
                            <div className="content mt-7">
                                <h5 className="h5 text-lg font-semibold">Phone</h5>
                                <p className="text-slate-400 mt-3">Call us for expert installation services including DStv, CCTV, TV mounting, and more.</p>
                                <div className="mt-5">
                                    <Link to="tel:+27721766985" className="text-red-500 font-medium">+27 72 176 6985</Link>
                                </div>
                            </div>
                        </div>

                        <div className="text-center px-6">
                            <div className="relative text-transparent">
                                <div className="size-20 bg-red-500/5 text-red-500 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                    <FiMail />
                                </div>
                            </div>
                            <div className="content mt-7">
                                <h5 className="h5 text-lg font-semibold">Email</h5>
                                <p className="text-slate-400 mt-3">For inquiries and support, reach out to us via email.</p>
                                <div className="mt-5">
                                    <Link to="mailto:info@dstvcapetowninstallers.co.za" className="text-red-500 font-medium">info@dstvcapetowninstallers.co.za</Link>
                                </div>
                            </div>
                        </div>

                        <div className="text-center px-6">
                            <div className="relative text-transparent">
                                <div className="size-20 bg-red-500/5 text-red-500 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                    <FiMapPin />
                                </div>
                            </div>
                            <div className="content mt-7">
                                <h5 className="h5 text-lg font-semibold">Location</h5>
                                <p className="text-slate-400 mt-3">Find us at 142 Parrow Street, Cape Town, SA</p>
                                <div className="mt-5">
                                    <Link 
                                        to="#" 
                                        onClick={() => setModal(!modal)} 
                                        className="text-red-500 font-medium"
                                    >
                                        View on Google Maps
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <Switcher />
            {modal && (
                <div className="w-full h-screen bg-slate-900/80 fixed top-0 left-0 bottom-0 right-0 z-999 flex items-center justify-center">
                    <div className="w-full h-full px-5 md:px-40 md-py-20 py-5">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d105943.30075755258!2d18.46523815988526!3d-33.92239561055194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc5a50a2e8cc2b%3A0xfe87b80e35138d46!2sParow%20Civic%20Centre!5e0!3m2!1sen!2sza!4v1723773650305!5m2!1sen!2sza" 
                        width="100%" 
                        height="100%" 
                        allowfullscreen="" 
                        loading="lazy" 
                       >
                            
                        </iframe>
                        
                    </div>
                    <button 
                        className="text-slate-400 absolute top-[20px] right-[20px]" 
                        onClick={() => setModal(!modal)}
                    >
                        <FiX className="w-6 h-6" />
                    </button>
                </div>
            )}
        </>
    );
}
