import React from "react";
import { Link } from "react-router-dom";

import Navbar from "../../components/navbar";
import Blogs from '../../components/blogs';
import BlogSidebar from "../../components/blog-sidebar";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";

import blogImg from "../../assets/images/blog/7.jpg"; // Update the image source as needed

export default function BlogDetail7() {
    return (
        <>
            <Navbar navclass="defaultscroll is-sticky" navlight={true} manuclass="justify-end nav-light" />
            <section className="relative table w-full items-center py-36 bg-[url('../../assets/images/bg/cta.jpg')] bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-gradient-to-b from-slate-900/60 via-slate-900/80 to-slate-900"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="text-4xl leading-normal tracking-wider font-semibold text-white">Maintaining Your Openview System for Optimal Performance</h3>
                        <ul className="list-none mt-6">
                            <li className="inline-block text-white/50 mx-5"> <span className="text-white block">Author :</span> <span className="block">CT DStv & CCTV Experts Team</span></li>
                            <li className="inline-block text-white/50 mx-5"> <span className="text-white block">Date :</span> <span className="block">1st September 2024</span></li>
                            <li className="inline-block text-white/50 mx-5"> <span className="text-white block">Time :</span> <span className="block">7 Min Read</span></li>
                        </ul>
                    </div>
                </div>
                
                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-block">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">CT DStv & CCTV Experts</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><i className="mdi mdi-chevron-right"></i></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">Blog Detail</li>
                    </ul>
                </div>
            </section>

            <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-6">
                        <div className="lg:col-span-8 md:col-span-6">
                            <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-800">
                                <img src={blogImg} alt="Openview Maintenance" />
                                <div className="p-6">
                                    <h4 className="text-xl font-semibold mb-4">Maintaining Your Openview System for Optimal Performance</h4>
                                    <p className="text-slate-400 mb-4">Openview is a popular choice for free-to-air satellite television, offering a range of channels and content. To ensure your Openview system operates at its best, regular maintenance is crucial. This guide provides practical tips to keep your Openview system in optimal condition.</p>

                                    <h4 className="text-xl font-semibold mb-4">1. Regular Dish Alignment Checks</h4>
                                    <p className="text-slate-400 mb-4">Proper alignment of your satellite dish is essential for consistent signal reception. Over time, environmental factors such as wind or shifting foundations can affect dish alignment. Regularly check and adjust the dish to ensure it is correctly aligned with the satellite.</p>

                                    <h4 className="text-xl font-semibold mb-4">2. Inspect and Clean the LNB</h4>
                                    <p className="text-slate-400 mb-4">The LNB (Low Noise Block converter) is a critical component of your Openview system. Dirt, dust, and moisture can affect its performance. Periodically inspect and clean the LNB to prevent signal degradation. Use a dry cloth to gently clean the surface and avoid using water or cleaning agents.</p>

                                    <h4 className="text-xl font-semibold mb-4">3. Check Cable Connections</h4>
                                    <p className="text-slate-400 mb-4">Ensure all cables connecting the dish, LNB, and decoder are securely fastened and free from damage. Loose or damaged cables can lead to signal loss or poor performance. Tighten any loose connections and replace any damaged cables promptly.</p>

                                    <h4 className="text-xl font-semibold mb-4">4. Perform Regular System Updates</h4>
                                    <p className="text-slate-400 mb-4">Openview systems may receive software updates to improve functionality and fix bugs. Check for system updates regularly and follow the instructions provided by Openview to install them. Keeping your system up to date ensures you benefit from the latest features and improvements.</p>

                                    <h4 className="text-xl font-semibold mb-4">5. Maintain the Decoder</h4>
                                    <p className="text-slate-400 mb-4">The decoder is the heart of your Openview system. Regularly check its performance and functionality. Ensure it is placed in a well-ventilated area to prevent overheating. If you encounter any issues such as freezing or slow response, consider resetting the decoder or contacting customer support for assistance.</p>

                                    <h4 className="text-xl font-semibold mb-4">6. Keep the Dish Area Clear</h4>
                                    <p className="text-slate-400 mb-4">Ensure there are no obstructions around the satellite dish, such as tree branches or debris. Obstructions can block the signal and affect performance. Regularly clear the area around the dish to maintain a clear line of sight to the satellite.</p>

                                    <h4 className="text-xl font-semibold mb-4">7. Contact Professional Services When Needed</h4>
                                    <p className="text-slate-400 mb-4">While regular maintenance can address most issues, some problems may require professional intervention. If you experience persistent issues or notice significant degradation in performance, contact a qualified technician for a thorough inspection and repair.</p>

                                    <p className="text-slate-400 italic border-x-4 border-red-500 rounded-ss-xl rounded-ee-xl mt-3 p-3">" For reliable Openview maintenance services, trust CT DStv & CCTV Experts. Our skilled team is dedicated to keeping your system in top condition for uninterrupted viewing pleasure. "</p>
                                    <p className="text-slate-400 mt-3">Contact us today for expert assistance and ensure your Openview system remains at peak performance.</p>
                                </div>
                            </div>
                        </div>

                        <BlogSidebar />
                    </div>
                </div>

                <Blogs />
            </section>
            <Footer />
            <Switcher />
        </>
    )
}
