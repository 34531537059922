import listing1 from '../assets/images/listing/1.jpg'
import listing2 from '../assets/images/listing/2.jpg'
import listing3 from '../assets/images/listing/3.jpg'
import listing4 from '../assets/images/listing/4.jpg'
import listing5 from '../assets/images/listing/5.jpg'
import listing6 from '../assets/images/listing/6.jpg'
import listing7 from '../assets/images/listing/7.jpg'
import listing8 from '../assets/images/listing/8.jpg'
import listing9 from '../assets/images/listing/9.jpg'
import listing10 from '../assets/images/listing/10.jpg'
import listing11 from '../assets/images/listing/11.jpg'
import listing12 from '../assets/images/listing/12.jpg'

import client1 from '../assets/images/client/01.jpg'
import client2 from '../assets/images/client/02.jpg'
import client3 from '../assets/images/client/03.jpg'
import client4 from '../assets/images/client/04.jpg'
import client5 from '../assets/images/client/05.jpg'
import client6 from '../assets/images/client/06.jpg'
import client7 from '../assets/images/client/07.jpg'
import client16 from '../assets/images/client/16.jpg'

import blog1 from '../assets/images/blog/1.jpg'
import blog2 from '../assets/images/blog/2.jpg'
import blog3 from '../assets/images/blog/3.jpg'
import blog4 from '../assets/images/blog/4.jpg'
import blog5 from '../assets/images/blog/5.jpg'
import blog6 from '../assets/images/blog/6.jpg'
import blog7 from '../assets/images/blog/7.jpg'
import blog8 from '../assets/images/blog/8.jpg'
import blog9 from '../assets/images/blog/9.jpg'

import payment1 from '../assets/images/payments/visa.jpg'
import payment2 from '../assets/images/payments/american-express.jpg'
import payment3 from '../assets/images/payments/discover.jpg'
import payment4 from '../assets/images/payments/mastercard.jpg'

import {FiShoppingCart,FiDribbble, FiLinkedin,FiFacebook,FiInstagram,FiTwitter,FiMail,FiClock,FiActivity, FiUsers, FiGlobe,FiDollarSign,FiYoutube,FiHelpCircle,FiBookmark,FiSettings,FiGithub,FiGitlab} from "../assets/icons/vander"

export const topDestination = [
    {
        image:listing1,
        place:'Rome, Italy',
        hotels:'3 Hotels'
    },
    {
        image:listing2,
        place:'Singapore',
        hotels:'3 Hotels'
    },
    {
        image:listing3,
        place:'Paris, France',
        hotels:'3 Hotels'
    },
    {
        image:listing4,
        place:'Goa, India',
        hotels:'3 Hotels'
    },
    {
        image:listing5,
        place:'Whistler, Canada',
        hotels:'3 Hotels'
    },
    {
        image:listing6,
        place:'Lumpur, Malaysia',
        hotels:'3 Hotels'
    },
    {
        image:listing7,
        place:'Sydney, Australia',
        hotels:'3 Hotels'
    },
    {
        image:listing8,
        place:'Virginia Beach',
        hotels:'3 Hotels'
    },
]

export const serviceHighlights = [
    {
        image:listing1, // Replace with an image relevant to DStv installations
        service:'DStv Installations',
        description:'Professional setup and installation'
    },
    {
        image:listing2, // Replace with an image relevant to Openview services
        service:'Openview Services',
        description:'Seamless Openview setup and support'
    },
    {
        image:listing3, // Replace with an image relevant to TV mounting
        service:'TV Mounting',
        description:'Secure and stylish TV mounting solutions'
    },
    {
        image:listing4, // Replace with an image relevant to CCTV installation
        service:'CCTV Installation',
        description:'Advanced security camera installation'
    },
    {
        image:listing5, // Replace with an image relevant to Air Conditioning services
        service:'Air Conditioning',
        description:'Efficient AC installation and maintenance'
    },
    {
        image:listing6, // Replace with an image relevant to DStv repairs
        service:'DStv Repairs',
        description:'Quick and reliable DStv repairs'
    },
    {
        image:listing7, // Replace with an image relevant to Openview maintenance
        service:'Openview Maintenance',
        description:'Ongoing support and maintenance'
    },
    {
        image:listing8, // Replace with an image relevant to general service or contact info
        service:'Contact Us',
        description:'Get in touch for a free quote or consultation'
    },
];

export const packages = [
    {
        id:1,
        image:listing1,
        tagText:'10% Off',
        place:'Dubai',
        title:'Cuba Sailing Adventure',
        amount:'$ 58 / Day'
    },
    {
        id:2,
        image:listing2,
        place:'Italy',
        title:'Tour in New York',
        amount:'$ 58 / Day'
    },
    {
        id:3,
        image:listing3,
        place:'Maldivas',
        title:'Discover Greece',
        amount:'$ 58 / Day'
    },
    {
        id:4,
        image:listing4,
        place:'USA',
        title:'Museum of Modern Art',
        amount:'$ 58 / Day'
    },
    {
        id:5,
        image:listing5,
        place:'Bali',
        title:'Peek Mountain View',
        amount:'$ 58 / Day'
    },
    {
        id:6,
        image:listing6,
        tagText:'25% Off',
        place:'Bangkok',
        title:'Hot Baloon Journey',
        amount:'$ 58 / Day'
    },
    {
        id:7,
        image:listing7,
        place:'Singapore',
        title:'Orca Camp Kayaking Trip',
        amount:'$ 58 / Day'
    },
    {
        id:8,
        image:listing8,
        tagText:'20% Off',
        place:'Thailand',
        title:'Caño Cristales River Trip',
        amount:'$ 58 / Day'
    },
    {
        id:9,
        image:listing9,
        place:'Pattaya',
        title:'Osa Peninsula to Dominical',
        amount:'$ 58 / Day'
    },
    {
        id:10,
        image:listing10,
        place:'Lakshadweep',
        title:'History of The Emporer',
        amount:'$ 58 / Day'
    },
    {
        id:11,
        image:listing11,
        place:'Paris',
        title:'Wildness of Paris',
        amount:'$ 58 / Day'
    },
    {
        id:12,
        image:listing12,
        place:'London',
        title:'The Hills and Mountains',
        amount:'$ 58 / Day'
    },
]


export const services = [
    {
        id: 1,
        image: listing1, // Replace with an image relevant to DStv installations
        place: 'Cape Town',
        title: 'DStv Installation',
        description: 'Expert installation of DStv systems to ensure optimal performance and reception.',
        url: '/dstv-installation'
    },
    {
        id: 2,
        image: listing2, // Replace with an image relevant to Openview services
        place: 'Cape Town',
        title: 'Openview Services',
        description: 'Comprehensive Openview setup and support for a wide range of free-to-air channels.',
        url: '/openview-services'
    },
    {
        id: 3,
        image: listing3, // Replace with an image relevant to TV mounting
        place: 'Cape Town',
        title: 'TV Mounting',
        description: 'Professional TV mounting solutions for secure and stylish installations.',
        url: '/tv-mounting'
    },
    {
        id: 4,
        image: listing4, // Replace with an image relevant to CCTV installation
        place: 'Cape Town',
        title: 'CCTV Installation',
        description: 'Advanced CCTV installation to enhance security and monitor your property effectively.',
        url: '/cctv-installation'
    },
    {
        id: 5,
        image: listing5, // Replace with an image relevant to Air Conditioning services
        place: 'Cape Town',
        title: 'Air Conditioning Services',
        description: 'Efficient air conditioning installation and maintenance to keep you comfortable year-round.',
        url: '/air-conditioning-services'
    },
    {
        id: 6,
        image: listing6, // Replace with an image relevant to DStv repairs
        place: 'Cape Town',
        title: 'DStv Repairs',
        description: 'Reliable repair services to address any issues with your DStv system promptly.',
        url: '/dstv-repairs'
    },
    {
        id: 7,
        image: listing7, // Replace with an image relevant to Openview maintenance
        place: 'Cape Town',
        title: 'Openview Maintenance',
        description: 'Ongoing support and maintenance for your Openview system to ensure uninterrupted service.',
        url: '/openview-maintenance'
    },
];



export const ClientData = [
    {
        image: client1, // Replace with an image of the client or testimonial
        desc: '"The DStv installation service was top-notch. The team was professional and efficient, and the setup exceeded our expectations."',
        name: 'James van der Merwe',
        position: 'Business Owner'
    },
    {
        image: client2, // Replace with an image of the client or testimonial
        desc: '"Openview setup was a breeze with their help. Clear communication and excellent service throughout the process."',
        name: 'Laura Smith',
        position: 'Homeowner'
    },
    {
        image: client3, // Replace with an image of the client or testimonial
        desc: '"The TV mounting service was quick and precise. I highly recommend them for anyone looking to upgrade their home entertainment setup."',
        name: 'Michael Botha',
        position: 'Office Manager'
    },
    {
        image: client4, // Replace with an image of the client or testimonial
        desc: '"We had CCTV installed, and the security improvements are noticeable. Professional service and great after-installation support."',
        name: 'Samantha Brown',
        position: 'Property Manager'
    },
    {
        image: client16, // Replace with an image of the client or testimonial
        desc: '"Air conditioning installation was handled expertly. The team was knowledgeable and made sure everything was perfect before leaving."',
        name: 'Riaan du Plessis',
        position: 'Facility Manager'
    },
    {
        image: client6, // Replace with an image of the client or testimonial
        desc: '"Fantastic repair service for our DStv system. They resolved the issue quickly and provided clear instructions for future maintenance."',
        name: 'Nadine van Wyk',
        position: 'Homeowner'
    }
];

export const blogData = [
    {
        id: 1,
        image: blog1, // Replace with an image related to DStv installations
        date: '5th Aug 2024',
        title: 'Top Tips for a Seamless DStv Installation',
        desc: 'Learn how to prepare for a smooth and hassle-free DStv installation with our expert tips and advice.',
        tag: 'DStv',
        url: '/top-tips-for-a-seamless-dstv-installation' // Replace with the actual URL for this blog post
    },
    {
        id: 2,
        image: blog2, // Replace with an image related to Openview services
        date: '8th Aug 2024',
        title: 'Setting Up Openview: A Comprehensive Guide',
        desc: 'A step-by-step guide to setting up your Openview system for optimal performance and channel variety.',
        tag: 'Openview',
        url: '/step-by-step installation' // Replace with the actual URL for this blog post
    },
    {
        id: 3,
        image: blog3, // Replace with an image related to TV mounting
        date: '10th Aug 2024',
        title: 'Everything You Need to Know About TV Mounting',
        desc: 'Tips and tricks for mounting your TV securely and stylishly in any room of your home.',
        tag: 'TV Mounting',
        url: '/tv-mounting-blog' // Replace with the actual URL for this blog post
    },
    {
        id: 4,
        image: blog4, // Replace with an image related to CCTV installation
        date: '11th Aug 2024',
        title: 'Choosing the Right CCTV System for Your Home',
        desc: 'Guide to selecting and installing the best CCTV system to enhance security and peace of mind.',
        tag: 'CCTV',
        url: '/right-cctv-sytem' // Replace with the actual URL for this blog post
    },
    {
        id: 5,
        image: blog5, // Replace with an image related to Air Conditioning services
        date: '12th Aug 2024',
        title: 'How to Maintain Your Air Conditioning System',
        desc: 'Essential maintenance tips to keep your air conditioning system running efficiently throughout the year.',
        tag: 'Air Conditioning',
        url: '/maintain-your-air-condition' // Replace with the actual URL for this blog post
    },
    {
        id: 6,
        image: blog6, // Replace with an image related to DStv repairs
        date: '13th Aug 2024',
        title: 'Common DStv Issues and How to Fix Them',
        desc: 'Explore common DStv problems and learn how to troubleshoot and resolve them effectively.',
        tag: 'DStv Repairs',
        url: '/common-dstv-issue' // Replace with the actual URL for this blog post
    },
    {
        id: 7,
        image: blog7, // Replace with an image related to Openview maintenance
        date: '8th Aug 2024',
        title: 'Maintaining Your Openview System for Optimal Performance',
        desc: 'Tips for keeping your Openview system in top shape to ensure uninterrupted viewing experience.',
        tag: 'Openview Maintenance',
        url: '/maintaining-youropneview' // Replace with the actual URL for this blog post
    },
    {
        id: 8,
        image: blog8, // Replace with a general service-related image
        date: '9th Aug 2024',
        title: 'Why Regular Maintenance is Key to System Longevity',
        desc: 'The importance of regular maintenance for all your home systems to avoid costly repairs and ensure longevity.',
        tag: 'Maintenance',
        url: '/why-regular-maintenance-is-key' // Replace with the actual URL for this blog post
    },
    {
        id: 9,
        image: blog9, // Replace with a general service-related image
        date: '12th Aug 2024',
        title: 'The Ultimate Guide to Home Technology Services',
        desc: 'A comprehensive guide to the various home technology services available and how they can benefit you.',
        tag: 'Home Technology',
        url: '/the-ultimate-guide-to-home' // Replace with the actual URL for this blog post
    }
];


export const footerSocial = [
    {
        icon:FiShoppingCart,
        link:'https://1.envato.market/travosy-react'
    },
    {
        icon:FiDribbble,
        link:'https://dribbble.com/shreethemes'
    },
    {
        icon:FiLinkedin,
        link:'http://linkedin.com/company/shreethemes'
    },
    {
        icon:FiFacebook,
        link:'https://www.facebook.com/shreethemes'
    },
    {
        icon:FiInstagram,
        link:'https://www.instagram.com/shreethemes'
    },
    {
        icon:FiTwitter,
        link:'https://twitter.com/shreethemes'
    },
    {
        icon:FiMail,
        link:'mailto:support@shreethemes.in'
    },
]

export const footerCompany = [
    {
        name:'About us',
        link:'/aboutus'
    },
    {
        name:'Services',
        link:'/services'
    },
    {
        name:'Team',
        link:'/team'
    },
    {
        name:'Blog',
        link:'/blogs'
    },
]

export const placeImage = [
    listing1,listing2,listing3,listing4,listing5,listing6,listing7,listing8,listing9,listing10,listing11,listing2,listing3,listing4
]

export const faqData = [
    {
        id: 1,
        title: 'How do I book an installation in Cape Town?',
        desc: 'Booking an installation with CT DStv & CCTV Experts is simple. You can contact us through our website, give us a call, or visit our offices in Cape Town. We service all areas, including Edge Mead, Panorama, Bothasig, and more.'
    },
    {
        id: 2,
        title: 'Do you offer same-day installation services?',
        desc: 'Yes, we offer same-day installation services depending on availability. This service is available across various locations such as Paarl, Macassar, Klapmuts, and others. Please book early in the day to ensure we can accommodate your request.'
    },
    {
        id: 3,
        title: 'What areas do you service?',
        desc: 'CT DStv & CCTV Experts proudly services all areas within Cape Town and surrounding suburbs, including Bishopscourt, Salt River, Observatory, Woodstock, Mowbray, Llandudno, and many more. We are committed to providing top-notch services across the region.'
    },
    {
        id: 4,
        title: 'What do I need to prepare before installation?',
        desc: 'Before our team arrives, ensure the area where you want your DStv or CCTV installed is accessible. This applies to all areas including Landsdowne, Athlone, Woodside Retirement Village, Melkbosstrand, West Beach, and others. Clear any obstacles and provide a power source for efficient setup.'
    },
    {
        id: 5,
        title: 'What if I need support after installation?',
        desc: 'Our customer support team is always available to assist you with any issues after installation. Whether you’re in Bergvliet, Belleville, Constantia, or Tygerhof, we offer follow-up services and troubleshooting to ensure your system works perfectly.'
    },
    {
        id: 6,
        title: 'Can I get an installation in Tygervalley or Pinelands?',
        desc: 'Yes, we provide DStv and CCTV installation services in Tygervalley, Pinelands, Eerste River, Zevenwacht, Yzerfontein, and many other locations. Contact us to schedule your installation today.'
    },
    {
        id: 7,
        title: 'Do you service areas outside of Cape Town?',
        desc: 'Absolutely, we service areas like Riverdale, Mossel Bay, Worcester, Newlands, and even further out to places like Gansbaai and Claremont. No matter where you are, CT DStv & CCTV Experts is here to help.'
    },
    {
        id: 8,
        title: 'What if I live in a remote location like Betty\'s Bay or Pringle Bay?',
        desc: 'We provide installation services even in remote locations such as Betty\'s Bay, Meadbridge, Pringle Bay, Darling, and others. Just reach out, and we will make the necessary arrangements.'
    },
    {
        id: 9,
        title: 'Are there any areas you don’t service?',
        desc: 'We strive to cover as many locations as possible, including St Helena Bay, Robertson, Montague, and Struisbaai. If you’re unsure, just give us a call to confirm service availability in your area.'
    },
    {
        id: 10,
        title: 'How soon can I get an installation in areas like Ceres, Paarl, or Wellington?',
        desc: 'We aim to provide prompt services in all areas, including Ceres, Paarl, Wellington, and others. Contact us, and we will schedule an appointment as soon as possible.'
    },
    {
        id: 11,
        title: 'What if I need a DStv installation in Cape Town suburbs like Muizenberg or Durbanville?',
        desc: 'We cover all Cape Town suburbs, including Muizenberg, Durbanville, Fisantekraal, Malmesbury, and more. Our team is ready to assist with your installation needs.'
    },
    {
        id: 12,
        title: 'Do you offer services in holiday locations like Hermanus or Langebaan?',
        desc: 'Yes, we provide installation services in popular holiday destinations such as Hermanus, Langebaan, and surrounding areas. Ensure your holiday home is equipped with the best entertainment and security solutions.'
    },
    {
        id: 13,
        title: 'Can I get support in Cape Town central areas like Sea Point or Green Point?',
        desc: 'We offer full support and installation services in central Cape Town areas including Sea Point, Green Point, Hout Bay, and more. Our team is ready to assist wherever you are located.'
    },
    {
        id: 14,
        title: 'What installation services are available in locations like Kuils River or Delft?',
        desc: 'We offer comprehensive installation services in areas such as Kuils River, Delft, Parow, Blue Downs, Mfuleni, and others. Contact us to schedule your installation today.'
    },
    {
        id: 15,
        title: 'Do you service locations like Goodwood, Parklands, and Tableview?',
        desc: 'Yes, we service Goodwood, Parklands, Tableview, Kensington, Maitland, and Brooklyn, providing top-quality DStv and CCTV installations.'
    },
    {
        id: 16,
        title: 'How do I book an installation in Somerset or Strand?',
        desc: 'Booking an installation in areas like Somerset, Strand, Gordon’s Bay, or Brackenfell is easy. Simply contact us through our website or give us a call, and we’ll arrange an appointment that suits you.'
    },
    {
        id: 17,
        title: 'Can you install systems in Stellenbosch or Franschhoek?',
        desc: 'Yes, we provide installation services in Stellenbosch, Franschhoek, Saldanha, Plattekloof, and many other areas. We ensure your home or business is equipped with the latest DStv and CCTV technology.'
    },
    {
        id: 18,
        title: 'What if I need an installation in Mitchell’s Plain or Strandfontein?',
        desc: 'We cover all areas, including Mitchell’s Plain, Strandfontein, Tafelsig, and beyond. Contact us to get started with your installation today.'
    },
    {
        id: 19,
        title: 'Do you provide services in Simon\'s Town or Kommetjie?',
        desc: 'Yes, we offer our services in Simon\'s Town, Kommetjie, Weslake, and other nearby areas. Our team is dedicated to providing excellent service no matter where you are.'
    }
];


export const tourDetailAbout = [
    {
        icon: FiClock,
        name:'Duration',
        title:'2 day'
    },
    {
        icon: FiActivity,
        name:'Type',
        title:'Adventure'
    },
    {
        icon: FiUsers,
        name:'Group Size:',
        title:'50 Peoples'
    },
    {
        icon: FiGlobe,
        name:'Languages',
        title:'English'
    },
    {
        icon: FiDollarSign,
        name:'$50 / Person',
        title:'1 Day'
    },
]

export const teamData = [
    {
        image:client4,
        name:'Jack John',
        possition:'Agent'
    },
    {
        image:client5,
        name:'Krista John',
        possition:'Agent'
    },
    {
        image:client6,
        name:'Roger Jackson',
        possition:'Agent'
    },
    {
        image:client7,
        name:'Johnny English',
        possition:'Agent'
    },
]

export const paymentData = [
    {
        image:payment1,
        title:'Visa ending in 4578',
        date:'Expires in 13/03/2024'
    },
    {
        image:payment2,
        title:'American Express ending in 4578',
        date:'Expires in 05/05/2024'
    },
    {
        image:payment3,
        title:'Discover ending in 4578',
        date:'Expires in 19/06/2024'
    },
    {
        image:payment4,
        title:'Master Card ending in 4578',
        date:'Expires in 20/06/2024'
    },
]

export const userSocialData = [
    {
        icon:FiTwitter,
        name:'Twitter',
        placeHolder:'Twitter Profile Name',
        desc:'Add your Twitter username (e.g. jesus).'
    },
    {
        icon:FiFacebook,
        name:'Facebook',
        placeHolder:'Facebook Profile Name',
        desc:'Add your Facebook username (e.g. jesus).'
    },
    {
        icon:FiInstagram,
        name:'Instagram',
        placeHolder:'Instagram Profile Name',
        desc:'Add your Instagram username (e.g. jesus).'
    },
    {
        icon:FiLinkedin,
        name:'Linkedin',
        placeHolder:'Linkedin Profile Name',
        desc:'Add your Linkedin username (e.g. jesus).'
    },
    {
        icon:FiYoutube,
        name:'Youtube',
        placeHolder:'Youtube url',
        desc:'Add your Youtube url.'
    },
]

export const helpAbout = [
    {
        icon: FiHelpCircle,
        title: 'FAQs',
        desc: 'Find answers to the most common questions about our services and policies.',
        link: '/helpcenter-faqs'
    },
    {
        icon: FiBookmark,
        title: 'Guides / Support',
        desc: 'Access detailed guides and support resources to assist you with our products.',
        link: '/contact'
    },
    {
        icon: FiSettings,
        title: 'Support Request',
        desc: 'Submit a support request if you need personalized help or have specific issues.',
        link: '/contact'
    }
];

export const guidesData = [
    {
        title:'Getting started',
        subData:[
            'Deciding to purchase','List your space','Landing an experience or adventure','Top uses questions'
        ]
    },
    {
        title:'Your calendar',
        subData:[
            'Pricing & availability','Booking settings','Responding to enquiries & requests','Snoozing or deactivating your listing'
        ]
    },
    {
        title:'Your listings',
        subData:[
            'Updating your listing','Neighbourhoods','Listing photos & photography','Travosy Plus','API-connected software'
        ]
    },
    {
        title:'How payouts work',
        subData:[
            'Getting paid','Adding payout info','Your payout status','Donations','Taxes'
        ]
    },
    {
        title:'Your reservations',
        subData:[
            'Travosy safely','Travosy Experiences and Adventures','Changing a reservation','Cancelling a reservation','Long-term reservations'
        ]
    },
    {
        title:'Reservation help',
        subData:[
            'Help with a reservation or guest','Guest cancellations',
        ]
    },
    {
        title:'Your account',
        subData:[
            'Your profile','Account security','Identification & verifications','Reviews','Superhost status'
        ]
    },
]
export const restrictions = [
    'Unauthorized duplication or distribution of our content is strictly prohibited.',
    'Reselling or redistributing our services without prior consent is not allowed.',
    'Use of our services for illegal activities is strictly forbidden.',
    'Any tampering or unauthorized modifications to installed systems will void warranties.',
    'Personal data collected through our services should not be shared with third parties without consent.',
    'You are restricted from misrepresenting our services or brand in any manner.'
];

export const blogSocial = [
    FiFacebook,FiInstagram,FiTwitter,FiLinkedin,FiGithub,FiYoutube,FiGitlab
]