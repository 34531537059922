import React from "react";
import { Link } from "react-router-dom";

import Navbar from "../../components/navbar";
import Blogs from '../../components/blogs';
import BlogSidebar from "../../components/blog-sidebar";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";

import blogImg from "../../assets/images/blog/8.jpg"; // Update the image source as needed

export default function BlogDetail8() {
    return (
        <>
            <Navbar navclass="defaultscroll is-sticky" navlight={true} manuclass="justify-end nav-light" />
            <section className="relative table w-full items-center py-36 bg-[url('../../assets/images/bg/cta.jpg')] bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-gradient-to-b from-slate-900/60 via-slate-900/80 to-slate-900"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="text-4xl leading-normal tracking-wider font-semibold text-white">Why Regular Maintenance is Key to System Longevity</h3>
                        <ul className="list-none mt-6">
                            <li className="inline-block text-white/50 mx-5"> <span className="text-white block">Author :</span> <span className="block">CT DStv & CCTV Experts Team</span></li>
                            <li className="inline-block text-white/50 mx-5"> <span className="text-white block">Date :</span> <span className="block">5th September 2024</span></li>
                            <li className="inline-block text-white/50 mx-5"> <span className="text-white block">Time :</span> <span className="block">6 Min Read</span></li>
                        </ul>
                    </div>
                </div>
                
                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-block">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">CT DStv & CCTV Experts</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><i className="mdi mdi-chevron-right"></i></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">Blog Detail</li>
                    </ul>
                </div>
            </section>

            <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-6">
                        <div className="lg:col-span-8 md:col-span-6">
                            <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-800">
                                <img src={blogImg} alt="System Maintenance" />
                                <div className="p-6">
                                    <h4 className="text-xl font-semibold mb-4">Why Regular Maintenance is Key to System Longevity</h4>
                                    <p className="text-slate-400 mb-4">Maintaining your home technology systems is crucial for ensuring their longevity and optimal performance. Regular maintenance helps to prevent issues before they become major problems, ensuring your systems run smoothly and efficiently for years to come. In this blog, we explore the importance of regular maintenance and the benefits it provides.</p>

                                    <h4 className="text-xl font-semibold mb-4">1. Prevents Major Failures</h4>
                                    <p className="text-slate-400 mb-4">Routine maintenance allows for the early detection of potential issues. By addressing minor problems before they escalate, you can avoid costly repairs and system failures. Regular check-ups and servicing help to ensure that components are functioning correctly and prevent unexpected breakdowns.</p>

                                    <h4 className="text-xl font-semibold mb-4">2. Enhances Performance</h4>
                                    <p className="text-slate-400 mb-4">Keeping your systems well-maintained ensures they operate at peak performance. Regular cleaning, updates, and inspections help to optimize system efficiency, improving overall functionality and user experience. Enhanced performance also means fewer interruptions and a more reliable service.</p>

                                    <h4 className="text-xl font-semibold mb-4">3. Increases Lifespan</h4>
                                    <p className="text-slate-400 mb-4">Routine maintenance extends the lifespan of your equipment. By regularly servicing and caring for your systems, you reduce wear and tear, preventing premature aging and component failures. This proactive approach ensures that your technology investment lasts longer and performs reliably.</p>

                                    <h4 className="text-xl font-semibold mb-4">4. Reduces Operational Costs</h4>
                                    <p className="text-slate-400 mb-4">Well-maintained systems are more energy-efficient and cost-effective. Regular maintenance helps to identify and fix issues that may cause inefficiencies or increased energy consumption. By optimizing performance, you can reduce operational costs and save on utility bills.</p>

                                    <h4 className="text-xl font-semibold mb-4">5. Ensures Safety and Compliance</h4>
                                    <p className="text-slate-400 mb-4">Maintaining your systems helps to ensure safety and compliance with industry standards. Regular inspections and servicing ensure that equipment is operating safely and meets relevant safety regulations. This is particularly important for systems that handle sensitive data or critical functions.</p>

                                    <h4 className="text-xl font-semibold mb-4">6. Provides Peace of Mind</h4>
                                    <p className="text-slate-400 mb-4">Knowing that your systems are well-maintained provides peace of mind. Regular maintenance reduces the likelihood of unexpected issues and ensures that you can rely on your technology when you need it most. This confidence in system reliability enhances your overall satisfaction and reduces stress.</p>

                                    <h4 className="text-xl font-semibold mb-4">7. Improves Resale Value</h4>
                                    <p className="text-slate-400 mb-4">If you ever decide to sell or upgrade your technology, well-maintained systems are more attractive to buyers. Regular servicing and care can improve the resale value of your equipment, making it a worthwhile investment for future upgrades or sales.</p>

                                    <p className="text-slate-400 italic border-x-4 border-red-500 rounded-ss-xl rounded-ee-xl mt-3 p-3">" At CT DStv & CCTV Experts, we emphasize the importance of regular maintenance to keep your systems running smoothly and efficiently. Contact us today to schedule your maintenance services and ensure the longevity of your technology. "</p>
                                    <p className="text-slate-400 mt-3">For more information on our maintenance services or to book an appointment, visit our website or get in touch with our team.</p>
                                </div>
                            </div>
                        </div>

                        <BlogSidebar />
                    </div>
                </div>

                <Blogs />
            </section>
            <Footer />
            <Switcher />
        </>
    )
}
