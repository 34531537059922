import React from "react";
import { Link } from "react-router-dom";
import { FiPhone } from '../assets/icons/vander';

export default function GetInTouch() {
    return (
        <div className="container relative md:mt-24 mt-16">
            <div className="grid grid-cols-1 text-center">
                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
                    Need Installation Assistance? Get in Touch!
                </h3>
                <p className="text-slate-400 max-w-xl mx-auto">
                    Whether you need DStv setup, CCTV installation, or any other home installation service, our team is here to help you. Contact us to get started!
                </p>
                <div className="mt-6">
                    <Link 
                        to="/contact" 
                        className="py-2 px-5 inline-flex items-center font-semibold tracking-wide align-middle duration-500 text-base text-center bg-red-500 text-white rounded-md mt-2"
                    >
                        <FiPhone className="w-5 h-5 me-2" />
                        Contact Us
                    </Link>
                </div>
            </div>
        </div>
    );
}
