import React from "react";
import { Link } from "react-router-dom";

import Navbar from "../../components/navbar";
import Blogs from '../../components/blogs';
import BlogSidebar from "../../components/blog-sidebar";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";

import blogImg from "../../assets/images/blog/9.jpg";

export default function BlogDetail1() {
    return (
        <>
            <Navbar navclass="defaultscroll is-sticky" navlight={true} manuclass="justify-end nav-light" />
            <section className="relative table w-full items-center py-36 bg-[url('../../assets/images/bg/cta.jpg')] bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-gradient-to-b from-slate-900/60 via-slate-900/80 to-slate-900"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="text-4xl leading-normal tracking-wider font-semibold text-white">Top Tips for a Seamless DStv Installation</h3>
                        <ul className="list-none mt-6">
                            <li className="inline-block text-white/50 mx-5"> <span className="text-white block">Author :</span> <span className="block">CT DStv & CCTV Experts Team</span></li>
                            <li className="inline-block text-white/50 mx-5"> <span className="text-white block">Date :</span> <span className="block">19th August 2024</span></li>
                            <li className="inline-block text-white/50 mx-5"> <span className="text-white block">Time :</span> <span className="block">5 Min Read</span></li>
                        </ul>
                    </div>
                </div>
                
                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-block">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">CT DStv & CCTV Experts</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><i className="mdi mdi-chevron-right"></i></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">Blog Detail</li>
                    </ul>
                </div>
            </section>

            <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-6">
                        <div className="lg:col-span-8 md:col-span-6">
                            <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-800">
                                <img src={blogImg} alt="Professional DStv Installation Service" />
                                <div className="p-6">
                                    <h4 className="text-xl font-semibold mb-4">Introduction to DStv Installation</h4>
                                    <p className="text-slate-400 mb-4">Installing DStv can seem like a daunting task, but with the right preparation and professional help, you can ensure a smooth and hassle-free setup. In this guide, we'll walk you through the essential tips for a seamless DStv installation.</p>

                                    <h4 className="text-xl font-semibold mb-4">Benefits of Professional Installation</h4>
                                    <p className="text-slate-400 mb-4">Opting for professional DStv installation ensures that your system is set up correctly and efficiently. Professionals bring expertise, experience, and tools that guarantee optimal performance and signal strength. Additionally, they can help avoid common installation mistakes that could affect your viewing experience.</p>

                                    <h4 className="text-xl font-semibold mb-4">Common Installation Challenges</h4>
                                    <p className="text-slate-400 mb-4">Some common challenges include signal interference, incorrect alignment of the satellite dish, and inadequate cable management. Understanding these issues can help you prepare better and communicate effectively with your installer.</p>

                                    <h4 className="text-xl font-semibold mb-4">Step-by-Step Installation Process</h4>
                                    <p className="text-slate-400 mb-4">1. **Site Survey**: Assess the best location for the satellite dish and receiver.  
                                    2. **Installation of the Satellite Dish**: Mount the dish on a stable surface and align it correctly.  
                                    3. **Connecting the Equipment**: Connect the dish to the receiver and ensure all cables are securely attached.  
                                    4. **Signal Testing**: Check the signal strength and make necessary adjustments.  
                                    5. **Final Setup**: Complete the installation and perform a final check to ensure everything is working properly.</p>

                                    <h4 className="text-xl font-semibold mb-4">Tips for Optimal Signal Reception</h4>
                                    <p className="text-slate-400 mb-4">Ensure the satellite dish is positioned away from obstructions like trees or buildings. Regularly check and clean the dish to remove debris that might block the signal. Consider using a signal booster if you experience weak reception.</p>

                                    <h4 className="text-xl font-semibold mb-4">Post-Installation Troubleshooting</h4>
                                    <p className="text-slate-400 mb-4">If you encounter issues after installation, check connections and cables first. Ensure that the dish alignment has not shifted and that there are no new obstructions. Contact your service provider or installer if problems persist.</p>

                                    <h4 className="text-xl font-semibold mb-4">FAQs about DStv Installation</h4>
                                    <p className="text-slate-400 mb-4">**Q: How long does a DStv installation take?**  
                                    A: On average, a professional installation can take 1-2 hours depending on the complexity of the setup.</p>
                                    <p className="text-slate-400 mb-4">**Q: Can I install DStv myself?**  
                                    A: While it is possible, we recommend professional installation to ensure proper setup and avoid potential issues.</p>
                                    <p className="text-slate-400 mb-4">**Q: What should I do if I experience signal loss?**  
                                    A: Check the dish alignment and connections. If issues persist, contact your service provider for support.</p>
                                    
                                    <p className="text-slate-400 italic border-x-4 border-red-500 rounded-ss-xl rounded-ee-xl mt-3 p-3">" Our commitment to quality service is what sets us apart. Trust us to handle your installations with the utmost care and professionalism. "</p>
                                    <p className="text-slate-400 mt-3">Whether you need TV mounting, Openview installations, or even air conditioning services, we are here to help. Contact us today for reliable and efficient service.</p>
                                </div>
                            </div>
                        </div>

                        <BlogSidebar />
                    </div>
                </div>

                <Blogs />
            </section>
            <Footer />
            <Switcher />
        </>
    )
}
