import React from "react";
import { Link } from "react-router-dom";

import Navbar from "../../components/navbar";
import GetInTouch from "../../components/get-in-touch";
import HelpcenterAbout from "../../components/helpcenter-about";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";

import {FiHelpCircle} from "../../assets/icons/vander"

export default function Helpcenter(){
    return(
        <>
        <Navbar navclass="defaultscroll is-sticky" navlight={true} manuclass="justify-end nav-light"/>
        <section className="relative table w-full py-36 bg-[url('../../assets/images/bg/cta.jpg')] bg-center bg-no-repeat bg-cover">
            <div className="absolute inset-0 bg-black opacity-80"></div>
            <div className="container relative">
                <div className="grid grid-cols-1 pb-8 text-center mt-10">
                    <h3 className="mb-6 text-4xl leading-normal tracking-wider font-semibold text-white">Hello ! <br/> How can we help you?</h3>
                </div>
            </div>
            
            <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                <ul className="tracking-[0.5px] mb-0 inline-block">
                    <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">CT DStv & CCTV Experts</Link></li>
                    <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><i className="mdi mdi-chevron-right"></i></li>
                    <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">Helpcenter</li>
                </ul>
            </div>
        </section>

        <section className="relative md:py-24 py-16">
            <HelpcenterAbout/>

            <div className="container relative md:mt-24 mt-16">
                <div className="grid grid-cols-1 pb-8 text-center">
                    <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Get Started</h3>

                    <p className="text-slate-400 max-w-xl mx-auto">Need assistance with your DStv installation, CCTV setup, or home security solutions? We are here to help!</p>
                </div>

                <div className="grid md:grid-cols-2 grid-cols-1 mt-8 gap-6">
                    <div className="flex">
                        <FiHelpCircle className="fea icon-ex-md text-red-500 me-3 mt-1 size-6"></FiHelpCircle>
                        <div className="flex-1">
                            <h5 className="mb-2 text-xl font-semibold">How do our services work?</h5>
                            <p className="text-slate-400">Our experts provide top-notch DStv installations and CCTV setups across Cape Town. We ensure that all systems are installed and configured to meet your needs.</p>
                        </div>
                    </div>
                    
                    <div className="flex">
                        <FiHelpCircle className="fea icon-ex-md text-red-500 me-3 mt-1 size-6"></FiHelpCircle>
                        <div className="flex-1">
                            <h5 className="mb-2 text-xl font-semibold">What areas do you service?</h5>
                            <p className="text-slate-400">We cover a wide range of areas in Cape Town including Edgemead, Panorama, Bothasig, and more. Check our service area list for full coverage.</p>
                        </div>
                    </div>
                    
                    <div className="flex">
                        <FiHelpCircle className="fea icon-ex-md text-red-500 me-3 mt-1 size-6"></FiHelpCircle>
                        <div className="flex-1">
                            <h5 className="mb-2 text-xl font-semibold">How can I book an installation?</h5>
                            <p className="text-slate-400">Booking an installation is simple. Just visit our booking page, choose your service, and pick a time that suits you. We'll take care of the rest!</p>
                        </div>
                    </div>
                    
                    <div className="flex">
                        <FiHelpCircle className="fea icon-ex-md text-red-500 me-3 mt-1 size-6"></FiHelpCircle>
                        <div className="flex-1">
                            <h5 className="mb-2 text-xl font-semibold">Do you offer after-sales support?</h5>
                            <p className="text-slate-400">Yes, we provide comprehensive after-sales support to ensure your systems are always running smoothly. Contact us anytime for assistance.</p>
                        </div>
                    </div>
                </div>
            </div>

            <GetInTouch/>
        </section>
        <Footer/>
        <Switcher/>
        </>
    )
}
